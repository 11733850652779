import axios from "axios";
import { BASE_URL } from "../configs";

const URL = BASE_URL + "/categoryOffre";

export const getAllCategoryOffre = () => {
  return axios.get(`${URL}`);
};

export const postCategoryOffre = (data) => {
  return axios.post(`${URL}`, data);
};

export const updateCategoryOffre = (data) => {
  const dataUpdate = {
    name_categorie_offre: data.name_categorie_offre,
  };
  return axios.put(`${URL}/${data.id_categorie_offre}`, dataUpdate);
};

export const deleteCategoryOffre = (id) => {
  return axios.delete(`${URL}/${id}`);
};
