import { BASE_URL } from "../configs";
import { axiosInstanceCollab } from "./config";

const URL = BASE_URL + "/offre";
const URL2 = BASE_URL + "/offres";

export const addOffre = (form) => {
  return axiosInstanceCollab.post(`${URL}`, form);
};

export const getAllOffre = (search) => {
  return axiosInstanceCollab.post(`${URL2}`, search);
};

export const getOffreById = (id) => {
  return axiosInstanceCollab.get(`${URL}/${id}`);
};

export const updateOffreById = (id, data) => {
  return axiosInstanceCollab.put(`${URL}/${id}`, data);
};

export const deleteOffreById = (id) => {
  return axiosInstanceCollab.delete(`${URL}/${id}`);
};
