import axios from "axios";
import { BASE_URL } from "../configs";

const URL = BASE_URL + "/typeOffre";

export const getAllTypeOffre = () => {
  return axios.get(`${URL}`);
};

export const postTypeOffre = (data) => {
  return axios.post(`${URL}`, data);
};

export const updateTypeOffre = (data) => {
  const dataUpdate = {
    name_type_offre: data.name_type_offre,
  };
  return axios.put(`${URL}/${data.id_type_offre}`, dataUpdate);
};

export const deleteTypeOffre = (id) => {
  return axios.delete(`${URL}/${id}`);
};
