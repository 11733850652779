<template>
  <section class="bg-light h-100 p-3">
    <div class="d-flex justify-content-between">
      <h2>Liste des offres d'emplois</h2>
      <button class="btn btn-primary" @click="navigateNewOffre()">
        Ajouter
      </button>
    </div>
    <hr />
    <!-- filtre -->
    <div class="col-12">
      <div class="d-flex gap-4">
        <div class="col-md-3">
          <label for="inputName" class="form-label">Recherche</label>
          <input
            type="text"
            class="form-control"
            placeholder="ex: Développeur"
            required
            v-model="search.searchkey"
          />
        </div>

        <div class="col-md-3">
          <label for="inputName" class="form-label">Type</label>
          <select
            name="type-offre"
            id="type-offre"
            class="form-control"
            required
            v-model="search.id_type_offre"
          >
            <option value="categorie-0" style="display: none"></option>
            <option
              :value="type.id_type_offre"
              v-for="type in typeOffre"
              :key="type.id_type_offre"
            >
              {{ type.name_type_offre }}
            </option>
          </select>
        </div>

        <div class="col-md-3">
          <label for="inputName" class="form-label">Catégorie</label>
          <select
            name="categorie-offre"
            id="categorie-offre"
            class="form-control"
            required
            v-model="search.id_categorie_offre"
          >
            <option value="categorie-0" style="display: none"></option>
            <option
              :value="categorie.id_categorie_offre"
              v-for="categorie in categorieOffre"
              :key="categorie.id_categorie_offre"
            >
              {{ categorie.name_categorie_offre }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <table class="table table-hover table-responsive">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Titre</th>
            <th scope="col">Type</th>
            <th scope="col">Catégorie</th>
            <th scope="col">Profil</th>
            <th scope="col">Lieu</th>
            <th scope="col">Date</th>
            <th scope="col">Auteur</th>
            <th scope="col" class="text-center">Nombre de postulants</th>
            <th scope="col" class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in pageOfItems" :key="item.id_offre">
            <th scope="row">{{ item.id_offre }}</th>
            <td>{{ item.title_offre }}</td>
            <td>{{ item.name_type_offre }}</td>
            <td>{{ item.name_categorie_offre }}</td>
            <td>{{ item.profil_offre }}</td>
            <td>{{ item.lieu_offre }}</td>
            <td>
              {{
                moment(item.date_offre).calendar(null, {
                  sameWeek: "ddd",
                  sameElse: "DD MMMM YYYY",
                  sameDay: "[Aujourd'hui]",
                  nextDay: "[Demain]",
                  lastWeek: "dddd [dernier]",
                  nextWeek: "dddd",
                  lastDay: "[Hier]",
                })
              }}
            </td>
            <td>{{ item.fname_collab }}</td>
            <td class="text-center">{{ item.NbCandidat }}</td>
            <td class="text-center d-flex gap-3 justify-content-center">
              <!-- <div>
                <IcoEye class="cursor-pointer" @click="navigateView(item)" />
              </div> -->
              <div>
                <IcoFile
                  class="cursor-pointer"
                  v-if="checkIfMine(item.id_collab)"
                  @click="navigateListCandidat(item.id_offre)"
                />
              </div>
              <div>
                <IcoPen
                  v-if="checkIfMine(item.id_collab)"
                  class="cursor-pointer"
                  @click="navigateToOffre(item.id_offre)"
                />
              </div>
              <div>
                <IcoDelete
                  v-if="checkIfMine(item.id_collab)"
                  class="cursor-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDelete"
                  @click="initDelete(item.id_offre)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center">
        <CustomPagination
          :pageSize="5"
          :items.sync="initialData"
          @changePage="onChangePage"
        ></CustomPagination>
      </div>
    </div>
    <!-- modal delete début-->
    <div
      class="modal fade"
      id="modalDelete"
      tabindex="-1"
      aria-labelledby="modalLabelDelete"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelDelete">
              Êtes-vous sûr ?
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">
                L'offre d'emploi sera supprimé avec tous ses condidats
              </div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseDelete"
            >
              Annuler
            </button>
            <button
              v-if="loading"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button v-else @click="deleteOffre()" class="btn btn-primary ms-3">
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal delete fin-->
  </section>
</template>

<script>
//import IcoEye from "vue-material-design-icons/Eye.vue";
import IcoDelete from "vue-material-design-icons/Delete.vue";
import { getAllOffre, deleteOffreById } from "../../api/offre";
import CustomPagination from "../../components/public/Pagination.vue";
import IcoPen from "vue-material-design-icons/Pen.vue";
import IcoFile from "vue-material-design-icons/FileMultiple.vue";
import { getAllTypeOffre } from "../../api/typeOffre";
import { getAllCategoryOffre } from "../../api/categorieOffre";
import { success } from "../../utils/toast";
import { checkIfMine } from "../../utils/index";

export default {
  name: "OffreListe",
  components: {
    // IcoEye,
    IcoDelete,
    CustomPagination,
    IcoPen,
    IcoFile,
  },

  data() {
    return {
      idDelete: 0,
      search: {
        searchkey: "",
        id_type_offre: "",
        id_categorie_offre: "",
      },
      loading: true,
      pageOfItems: [],
      initialData: [],
      typeOffre: [],
      categorieOffre: [],
    };
  },
  mounted() {
    this.fetch();
    getAllTypeOffre().then((result) => {
      this.typeOffre = result.data;
    });
    getAllCategoryOffre().then((result) => {
      this.categorieOffre = result.data;
    });
  },
  watch: {
    search: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
  methods: {
    fetch() {
      getAllOffre(this.search).then((results) => {
        this.initialData = results.data;
        this.loading = false;
      });
    },
    navigateNewOffre() {
      this.$router.push(`/admin/nouvelle-offre`);
    },
    navigateToOffre(id) {
      this.$router.push(`/admin/offre/${id}`);
    },
    navigateListCandidat(param) {
      this.$router.push(`/admin/offre/candidat/${param}`).catch(() => {});
    },
    initDelete(id) {
      this.idDelete = id;
    },
    deleteOffre() {
      this.loading = true;
      deleteOffreById(this.idDelete).then(() => {
        this.fetch();
        this.loading = false;
        this.$refs.CloseDelete.click();
        success("Offre d'emploi supprimé");
      });
    },
    onChangePage(pageOfItems) {
      window.scrollTo(0, 0);
      this.pageOfItems = pageOfItems;
    },
    checkIfMine(id) {
      return checkIfMine(id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
