import store from "../store";

export const checkIfMine = (id) => {
  if (store.getters["collaborateurStore/meCollab"].isSuperAdmin) {
    return true;
  } else if (id === store.getters["collaborateurStore/meCollab"].id_collab) {
    return true;
  } else {
    return false;
  }
};

export const badgeStatus = (text) => {
  switch (text) {
    case "ACCEPT":
      return "bg-success";

    case "DECLINE":
      return "bg-danger";

    case "UNTREATED":
      return "bg-primary";

    case "POTENTIAL":
      return "bg-purple";

    case "OLD":
      return "bg-dark";

    case "POSTE":
      return "bg-blue";
    default:
      break;
  }
};
export const colorStatus = (text) => {
  switch (text) {
    case "Accepté":
      return "#2ECC71";

    case "Refusé":
      return "#e71d36";

    case "Non traité":
      return "#ffcd03";

    case "potentielle":
      return "#7D3C98";

    case "ancien":
      return "#1d1d1d";

    case "en poste":
      return "#0DCAF0";
    default:
      break;
  }
};

export const showStatus = (text) => {
  switch (text) {
    case "ACCEPT":
      return "Accepté";

    case "DECLINE":
      return "Refusé";

    case "UNTREATED":
      return "Non traité";

    case "POTENTIAL":
      return "potentielle";

    case "OLD":
      return "ancien";

    case "POSTE":
      return "en poste";

    default:
      break;
  }
};
